import React from 'react'
import Layout from '../../components/Layout'

export default () => (
  <Layout>
    <section className="section">
      <div className="container">
        <div className="content open-sans-regular">
          <h1>Teşekkürler!</h1>
          <p>
            Verdiğin bilgilerle oluşturacağımız artvizitin, en yakın zamanda{' '}
            <a href="/">artvizit.co</a>'da olacak
          </p>
          <p>
            Hazır olduğunda{' '}
            <a href="https://www.instagram.com/artvizit.co/">
              instagram.com/artvizit.co/
            </a>{' '}
            instagram hesabından duyuracağız, bizi takip etmeyi unutma :)
          </p>
        </div>
      </div>
    </section>
  </Layout>
)
